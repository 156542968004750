import * as React from 'react'
import { graphql } from 'gatsby'
import rehypeReact from 'rehype-react'

import IndexLayout from '../layouts'
import StyledPage from '../components/StyledPage'
import Container from '../components/Container'

import { HostTable } from '../components/HostTable/HostTable'

interface PageTemplateProps {
  data: {
    post: {
      html: string
      excerpt: string
      frontmatter: {
        title: string
      }
      htmlAst: any
    }
  }
}

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { HostTable }
}).Compiler

const PageTemplate: React.SFC<PageTemplateProps> = ({ data }) => {
  return (
    <IndexLayout>
      <StyledPage>
        <Container>
          <h1>{data.post.frontmatter.title}</h1>
          <div>{renderAst(data.post.htmlAst)}</div>
        </Container>
      </StyledPage>
    </IndexLayout>
  )
}

export default PageTemplate

export const pageFields = graphql`
  fragment pageFields on MarkdownRemark {
    fields {
      slug
    }
    htmlAst
    excerpt(format: HTML, truncate: true)
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      title
    }
  }
`

export const query = graphql`
  query($id: String) {
    post: markdownRemark(id: { eq: $id }) {
      ...pageFields
    }
  }
`
